<template>
    <div :class="[device_platform]"
         class="get-container">
        <div class="blacking"
             @click="hideSelf"></div>
        <div class="input-container">
            <div class="closer" @click="hideSelf">x</div>
            <div class="logos-container">
                <!-- <div class="name-container">
                    <div class="dealer">
                        {{CONSTANTS.dealer.toUpperCase()}}
                    </div>
                    <div class="dealer-text">
                        ОФИЦИАЛЬНЫЙ ДИЛЕР
                    </div>
                </div> -->
                <div class="logo"></div>
            </div>
            <div class="office-contacts-container" v-for="office in CONSTANTS.offices">
                <div  v-if="data.callback_title">
                    <div class="callback_title">
                        <p>
                            {{data.callback_title}}
                        </p>
                    </div>
                </div>
                <div v-else-if="data.callback_html">
                    <div class="callback_tilte" v-html="data.callback_html"></div>
                </div>
                <div v-else>
                    <div class="phone-container">
                        <div class="office-phone">
                            <a :href="'tel:' + office.phone_raw" class="phone" id="get-phone">
                                {{office.phone}}
                            </a>
                        </div>
                    </div>
                    <div class="address-worktime-container">
                        <div class="office-address-container">
                            <div class="office-address" @click="scrollTo('contacts')">
                                {{office.address.toUpperCase()}}
                            </div>
                            <div class="office-worktime" v-html="office.worktime"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="get-text" v-if="data.callback_text">
                {{data.callback_text}}
            </div>
            <div class="get-text">
                <slot></slot>
            </div>
            <callback-input v-on="$listeners" :data="data">
                {{data.button_text ? data.button_text : 'ОТПРАВИТЬ ЗАЯВКУ'}}
            </callback-input>
        </div>
    </div>
</template>

<script>
    import Mask from '../common/mask';
    import Keyup from '../common/keyup';
    import CallbackInput from './callback-form/callback-input';
    import Mixin from '../common/mixin';
    import $ from 'jquery';

    export default {
        name: 'full-get',
        components: {CallbackInput},
        mixins: [Mixin],
        directives: {Mask, Keyup},
        props: ['data'],
        data() {
            return {
                offices: this.CONSTANTS.offices
            }
        },
        mounted() {
            $('#get-phone').text($('#phone').text());
        },
        methods: {
            hideSelf() {
                this.$emit('close');
            },
            getAgreement() {
                this.$emit('getAgreement');
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../styles/constants.scss';

    .tablet {

    }

    .mobile {
        .input-container {
            width: 300px;
            padding:40px 20px 20px;
        }
        .callback_title{
            text-align: center;
        }
        .office-contacts-container {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 20px;
        }

        .phone-container {
            margin-bottom: 20px;
        }

        .callback-input {
            width: 100%;
        }

        .dealer-text {
            width: 120%;
        }

        .dealer {
            font-size: 20px;
        }

    }

    .blacking {
        position: fixed;
        top: -100vh;
        left: -100vw;
        bottom: -100vw;
        right: -100vh;
        background: #000;
        opacity: 0.5;
        z-index: 500;
    }

    .input-container {
        position: fixed;
        top: 18vh;
        left: 50%;
        transform: translateX(-50%);
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 510;
        padding: 20px 30px;
    }

    .closer {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        cursor: pointer;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logos-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .office-contacts-container {
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .logo {
        background: url("../images/logos/logo.svg") no-repeat center;
        background-size: contain;
        width: 345px;
        height: 49px;
        margin: 0 auto;
        cursor: pointer;
    }

    .name-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .dealer {
        font-weight: 500;
        color: #1f6fb6;
        font-size: 24px;
        padding: 0px 7px 0px 0px;
        border-right: 2px solid #1f6fb6;
    }

    .dealer-text {
        font-weight: 400;
        font-size: 16px;
        color: #1f6fb6;
        padding: 5px 0px;
    }

    .phone-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 500;
        color: white;
        font-size: 19px;
        height: 30px;
        justify-content: center;
        margin-bottom: 10px;
    }

    .callback_title {
        font-size: 19px;
        font-weight: 500;
        align-items: center;
    }

    .address-worktime-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .office-address {
        font-weight: 400;
        font-size: 16px;
    }

    .office-worktime {
        font-weight: 400;
        font-size: 16px;
    }


    .get-text {
        text-align: center;
        margin: 0 auto 10px;
    }
</style>
