<template>
  <div id="app" :class="{ webp: is_webp }">

    <!-- <div class="ny-label" v-if="show.ny_label">
      График работы салона в праздничные дни
      <button class="ny-label__btn btn-blue " @click="openNyPopup">Подробнее</button>
      <div class="ny-label__close" @click="closeNyLabel">✕</div>
    </div>  -->

    <block-header v-on="block_handlers"></block-header>
    <block-main v-on="block_handlers"></block-main>

    <block-advantages v-on="block_handlers" />
    <block-cars id="cars" v-on="block_handlers"></block-cars>
    <block-configurator id="configurator" v-on="block_handlers"></block-configurator>

    <block-offer id="offer" v-on="block_handlers"></block-offer>
    <block-map id="contacts" v-on="block_handlers"></block-map>
    <block-legal></block-legal>
    <block-footer></block-footer>
    <full-get v-if="show.get" :data="data" @close="closeThings" v-on="block_handlers">{{ data.text }}</full-get>
    <full-thanks v-if="show.thanks" @close="closeThings"> </full-thanks>
    <full-agreement v-if="show.agreement" @close="closeAgreement"></full-agreement>

    <full-worktime @close="closeThings" v-show="show.ny_popup" />
  </div>
</template>

<script>
import BlockHeader from "./components/block-header";
import BlockMain from "./components/block-main";
import BlockClock from './components/block-clock-megatimer';
import BlockLegal from "./components/block-legal";
import BlockCars from "./components/block-cars/block-cars.vue";
import BlockInterior from "./components/block-interior";
import BlockChoice from "./components/block-choice";
import BlockConfigurator from "./components/block-configurator";
import BlockOffer from "./components/block-offer";
import BlockMap from "./components/block-map";
import BlockFooter from "./components/block-footer";
import FullGet from "./components/full-get";
import FullThanks from "./components/full-thanks";
import FullAgreement from "./components/full-agreement";
import FullWorktime from "./components/full-worktime.vue";
import Mixin from "./common/mixin";

import axios from "axios";
import Send from "./common/send";
import $ from "jquery";
import smoothScrollTo from "./common/smoothScrollTo";
import BlockAdvantages from "./components/block-advantages";

export default {
  name: "app",
  components: {
    BlockAdvantages,
    FullAgreement,
    FullWorktime,
    BlockHeader,
    BlockCars,
    BlockInterior,
    BlockChoice,
    BlockConfigurator,
    BlockOffer,
    BlockFooter,
    BlockMap,
    BlockMain,
    BlockClock,
    FullGet,
    FullThanks,
    BlockLegal,
  },
  mixins: [Mixin],
  data: function () {
    return {
      show: {
        agreement: false,
        load: false,
        get: false,
        thanks: false,
        ny_label: true,
        ny_popup: false
      },
      data: {},
      b_cars_car: null,
      is_webp: true,
    };
  },
  mounted() {
    $("html").on("keyup", (event) => {
      if (event.keyCode === 27) {
        this.closeThings();
      }
    });
    history.scrollRestoration = "manual";
    let hash = window.location.hash.replace("#", "");
    if (hash) {
      this.scrollTo(hash);
    }
    this.canUseWebP();
  },
  methods: {
    canUseWebP() {
      let elem = document.createElement("canvas");

      if (!!(elem.getContext && elem.getContext("2d"))) {
        // was able or not to get WebP representation
        return (this.is_webp =
          elem.toDataURL("image/webp").indexOf("data:image/webp") == 0);
      }

      // very old browser like IE 8, canvas not supported
      return (this.is_webp = false);
    },
    closeNyLabel() {
      this.show.ny_label = false
    },
    openNyPopup() {
      this.show.ny_popup = true
    },
    closeThings() {
      this.show.ny_popup = false;
      this.show.get = false;
      this.show.thanks = false;
      this.show.agreement = false;
      this.data = {};
      this.unblockScroll();
    },
    closeAgreement() {
      this.show.agreement = false;
      if (!this.show.get) {
        this.unblockScroll();
      }
    },
    getAgreement() {
      this.show.agreement = true;

      this.blockScroll();
    },
    yaMetricaRequest(type) {
      if (
        window.yaCounter56456560 &&
        typeof window.yaCounter56456560.reachGoal === "function"
      ) {
        window.yaCounter56456560.reachGoal(type);
      } else {
        console.log("no yaCounter56456560 was found");
      }
    },
    handleGetCall(data) {
      this.yaMetricaRequest("click");
      this.show.get = true;
      this.data = data;
      this.blockScroll();

      if (!localStorage.getItem("open_form")) {
        ym(89627801,'reachGoal','open_form');
        localStorage.setItem("open_form", 1);
      }
    },
    scrollTo(where, delta) {
      let target = document.getElementById(where);
      if (!delta) delta = 0;
      smoothScrollTo($("#" + where).offset().top - delta);
    },
    setChoice(car) {
      this.b_cars_car = car;
    },
    callThere(event) {
      let cKeeper = this.CONSTANTS.cKeeper,
        manager_phone = this.CONSTANTS.need_manager_phone
          ? $("#phone")
            .text()
            .replace(/\s/g, "\u00A0")
            .replace(/-/g, "\u2011")
            .replace(/\D+/g, "")
          : "",
        req = Send(event, this.data, cKeeper, manager_phone);

      this.show.load = true;
      this.yaMetricaRequest("filled_form");
      if (cKeeper === "actioncall") {
        const finishCall = () => {
          this.closeThings();
          this.show.load = false;
          this.show.thanks = true;
          if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
              event: "callkeeper-call_order-ckaction",
              eventCategory: "callkeeper",
              eventAction: "call_order",
              eventLabel: "ckaction",
            });
          }
          console.log("done");
        };

        if (!req) {
          finishCall();
        } else {
          req.finally(finishCall);
        }
      } else {
        this.closeThings();
        this.show.load = false;
        this.show.thanks = true;
      }
    },
  },
  computed: {
    block_handlers() {
      return {
        getAgreement: this.getAgreement,
        scrollTo: this.scrollTo,
        callBack: this.callThere,
        getCall: this.handleGetCall,
        bCarsClicked: this.setChoice,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 115px;

  @media (max-width: 1615px) {
    padding-top: 115px;
  }

  @media (max-width: 800px) {
    padding-top: 80px;
  }
}

.ny-label {
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000000000;
  padding: 15px 40px;
  width: 100%;
  background: #1f6fb6;
  display: flex;
  justify-content: center;
  align-items: center;

  &__btn {
    margin-left: 20px;
  }

  &__close {
    position: absolute;
    right: 40px;
    cursor: pointer;
    font-size: 25px;
  }
}

@media (max-width: 800px) {
  .ny-label {
    top: auto;
    bottom: 0;
    padding: 10px;
    flex-direction: column;
    text-align: center;

    &__btn {
      margin-top: 10px;
      margin-left: 0;
    }

    &__close {
      right: 20px;
    }
  }
}
</style>
