import CF from './common-functions'; 
import CarsInfo from './cars-info';

const prices = CarsInfo.CARS.map(el => el.price);
const advantages = CarsInfo.CARS.map(el => el.advantage);
const payments = CarsInfo.CARS.map(el => el.payment);
const MAX_ADVANTAGE = Math.max.apply(null, advantages);
const MIN_PRICE = Math.min.apply(null, prices);
const MIN_PAYMENT = Math.min.apply(null, payments);

let date = CF.getNewDate();

const PHONE = '+7&nbsp;(812)&nbsp;777&#8209;92&#8209;47';

const PHONE_RAW = PHONE.replace(/\D+/g, "");


export default {
    phone: PHONE,
    phone_raw: PHONE_RAW,
    end_date: date,
    max_advantage: MAX_ADVANTAGE,
    min_price: MIN_PRICE,
    min_payment: MIN_PAYMENT,
    offices: [
        {
            name: '',
            address: 'Шоссе Революции, 86, Санкт-Петербург',
            phone: PHONE,
            phone_raw: PHONE_RAW,
            worktime: 'Отдел продаж 9:00-21:00<br />Сервис 8:00-21:00',
            coords: {
                x: 30.455179,
                y: 59.961679
            },
        },
    ],
    brand: 'Geely',
    dealer: 'АВТОСТИЛЬ',
    center_coords: {
        x: 30.455179,
        y: 59.961679
    },
    need_agreement: true,
    cKeeper: undefined,
    need_manager_phone: true
}
