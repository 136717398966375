<template>
  <div class="block-interior-container" :class="device_platform">
    <div class="header-text">Интерьер Geely</div>
    <div class="model-chooser">
      <div class="nav-container">
        <div
          class="nav-item"
          v-for="(car, index) in cars"
          :key="index"
          :class="{'active': cur_car.index === index}"
          @click="cur_car = {index: index, name: car.id}"
        >{{car.name}}</div>
      </div>
    </div>
    <div class="images-container" :id="cur_car.name">
      <div v-for="(img, index) in imgs_array" class="image" :class="'_' + img" :key="index"></div>
    </div>
  </div>
</template>

<script>
import Mixin from "../common/mixin";
import CARS from "../common/cars-info";
import filters from "../common/finance";
import CallbackInput from "./callback-form/callback-input";

export default {
  name: "block-interior",
  components: { CallbackInput },
  mixins: [Mixin, filters],
  data() {
    const valid_cars = CARS.CARS.filter((car) => car.interior);
    return {
      cars: valid_cars,
      cur_car: {
        index: 0,
        name: valid_cars[0].id,
      },
    };
  },
  computed: {
    imgs_array() {
      const ret = [];
      for (let i = 1; i <= this.cars[this.cur_car.index]["imgs"]; i++) {
        ret.push(i);
      }

      return ret;
    },
  },
  methods: {
    getAgreement() {
      this.$emit("getAgreement");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.mobile {
  &.block-interior-container {
  }
  .model-chooser {
    font-size: 18px;
  }
  .nav-container {
    max-width: 320px;
    padding: 0 10px;
    justify-content: space-around;
  }
  .images-container {
    @each $car in atlas, emgrand-x7, emgrand-7 {
      &##{$car} {
        .image {
          width: 100%;
          max-width: 500px;
          height: calc(33% - 10px);
          margin: 5px auto;
          @for $image from 1 through 2 {
            &._#{$image} {
              display: none;
            }
          }
          @for $image from 6 through 10 {
            &._#{$image} {
              display: none;
            }
          }
        }
        ._1 {
          &.image {
            height: calc(66% - 10px);
          }
        }
      }
    }
  }
}

.tablet {
  &.block-interior-container {
  }
  .nav-container {
    max-width: 600px;
  }
  .images-container {
    @each $car in atlas, emgrand-x7, emgrand-7 {
      &##{$car} {
        .image {
          width: 50%;
          height: calc(33% - 10px);
          margin: 5px 0;
          @for $image from 6 through 10 {
            &._#{$image} {
              display: none;
            }
          }
        }
        ._1 {
          &.image {
            height: calc(66% - 10px);
          }
        }
      }
    }
  }
}

.block-interior-container {
  width: 100%;
  max-width: 1920px;
}

.model-chooser {
  width: 100%;
  background: $blue;
  padding: 20px 0;
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
}

.nav-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 800px;
  justify-content: space-between;
  margin: auto;
}

.nav-item {
  cursor: pointer;
  &.active {
    text-decoration: underline;
  }
}

.images-container {
  width: 100%;
  height: 750px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: #000;
  padding: 60px 0;
}
@each $car in atlas, emgrand-x7 {
  ##{$car} {
    .image {
      width: 25%;
      height: calc(33% - 10px);
      margin: 5px 0;
      @for $image from 1 through 10 {
        &._#{$image} {
          background: url("../images/interior/#{$car}/#{$image}.jpg")
            no-repeat
            center;
          background-size: cover;
        }
      }
    }
  }
}

#emgrand-7 {
  .image {
    width: 25%;
    height: calc(33% - 10px);
    margin: 5px 0;
    @for $image from 1 through 8 {
      &._#{$image} {
        background: url("../images/interior/emgrand-7/#{$image}.jpg")
          no-repeat
          center;
        background-size: cover;
      }
    }
  }
}

#atlas,
#emgrand-7,
#emgrand-x7 {
  ._1,
  ._7 {
    &.image {
      height: calc(66% - 10px);
    }
  }

  .image {
    &._3,
    &._4,
    &._6 {
      background-position-y: 15%;
    }
  }
}

#emgrand-7,
#emgrand-x7 {
  .image {
    &._2,
    &._5,
    &._9 {
      background-position-y: 0;
    }
  }
}
</style>
