<template>
  <div class="wrapper">
    <div :id="'slider-' + model" class="slider swiper">
      <div class="slider__wrapper swiper-wrapper">
        <div
             v-for="(img, i) of slidesNumber" :key="`car-${i}`"
             class="slider__slide swiper-slide"
             @click="openGallery(i)"
        >
          <img
              :data-src="getSlideImg(i + 1)"
              class="slider__img swiper-lazy"
          />
        </div>
      </div>
    </div>

    <div
        class="navigation"
        :class="device_platform == 'desktop' ? 'vertical' : 'horizontal'"
    >
      <div :id="'prev-' + model" class="swiper-button-prev slider__button slider__button--prev">
        {{ getPrevArrow() }}
      </div>
      <div :id="'next-' + model" class="swiper-button-next slider__button slider__button--next">
        {{ getNextArrow() }}
      </div>
    </div>
  </div>

</template>


<script>
import Swiper from 'swiper'
import {Lazy} from "swiper/dist/js/swiper.esm";

import Mixin from "@/common/mixin";
export default {
  name: "page-slider",
  mixins: [Mixin],
  props: {
    model: String,
    slidesNumber: {
      type: Number,
      default: 5,
    },
  },
  data() {
    const swiper = null;
    return {
      swiper,
    };
  },
  mounted() {
    if (!this.swiper) {
      this.swiper = new Swiper("#slider-" + this.model, {
        slidesPerView: 3,
        spaceBetween: 10,
        direction: 'vertical',
        breakpoints: {
          1200: {
            direction: 'horizontal',
            slidesPerView: 5,
            // slidesPerView: 'auto',
            // watchSlidesProgress: true,
          },
          800: {
            slidesPerView: 4,
            direction: 'horizontal',
          },
          600: {
            slidesPerView: 3,
            direction: 'horizontal',
          },
        },
        navigation: {
          prevEl: "#prev-" + this.model,
          nextEl: "#next-" + this.model,
        },
        lazy: true,
      });
    }
  },
  methods: {
    getSlideImg(i){
      return require(`../../../images/cars/slides/thumbs/${this.model}/${i}.jpg`);
    },
    openGallery(index) {
      this.$emit('openGallery', this.model, index);
    },
    getPrevArrow(){
      return //this.device_platform == 'desktop' ? '🞁' : '🞀'
    },
    getNextArrow(){
      return //this.device_platform == 'desktop' ? '🞃' : '🞂'
    },
  },
};
</script>


<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  padding: 30px 0;
  position: relative;
}

.slider {
  height: 100%;
  overflow: hidden;

  &__wrapper {
    height: 100%;
  }

  &__slide {
    min-width: 0;
    width: auto;
    height: 90px !important;
    border: 2px solid #343e47;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__button {
    position: absolute;
    color: #333333;
    top: 15px;
    left: 50%;
    translate: -50% 0;
   // font-size: 30px;
   transform: rotate(-90deg);
    background: url('../../../images/icons/arrow-new.svg') no-repeat center/cover;

    &--next {
      top:auto;
      transform: rotate(90deg);
      bottom: -5px;
    }
  }
}

@media (max-width: 1200px) {
  .slider {
    &__slide {
      height: 100px;
    }

    &__button {
      left: -23px;
      top: 50%;
      translate: 0 -50%;
      transform: rotate(-180deg);

      &--next {
        left: auto;
        right: -23px;
        bottom: auto;
        transform: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .slider {
    &__slide {
      height: 26vw;
    }

    &__button {
      // translate: 0 20%;

      // &--next {
      //   translate: 0 20%;
      // }
    }
  }
}

</style>