<template>
    <div class="block-advantages-container" :class="device_platform">
        <div class="advantages-wrapper">
            <div class="advantage" v-for="advantage in advantages" :key="advantage.text"
                 @click.stop="getCall({type: 'sale', form:'cars', callback_title: advantage.callback ? advantage.callback : ''})">
                <div class="advantage-img" :class="advantage.img"></div>
                <div class="advantage-text" v-html="advantage.text"></div>
            </div>
        </div>
        <div class="advantage-button-next"></div>
        <div class="advantage-button-prev"></div>
    </div>
</template>

<script>
    import Mixin from '../common/mixin';
    import finance from '../common/finance';
    import Swiper from 'swiper';

    export default {
        name: "block-advantages",
        mixins: [Mixin, finance],
        data() {
            return {
                advantages: [
                    {
                        text: 'Без первого<br>взноса',
                        img: 'piggy-bank',
                        callback: 'Без первого взноса'
                    },
                    {
                        text: 'Кредит<br>от 0,01%',
                        img: 'credit',
                        callback: 'Кредит от 0,01%'
                    },
                    {
                        text: 'Рассрочка 0%',
                        img: 'calendar',
                        callback: 'Рассрочка 0%',
                    },
                    {
                        text: '5 лет гарантии<br>на новый GEELY',
                        img: 'guarantee',
                        callback: '5 лет гарантии на новый GEELY',
                    },
                    {
                        text: 'Доп. оборудование<br/> в подарок',
                        img: 'gift_black',
                        callback: 'Зимняя резина и сигнализация в подарок'
                    },
                    {
                        text: 'Скидка от автосалона<br>100 000 ₽',
                        img: 'bonus',
                        callback: 'Скидка от автосалона 100 000 ₽'
                    },
                ]
            }
        },
        methods: {
            getAgreement() {
                this.$emit('getAgreement');
            }
        },
        mounted() {
            if (window.innerWidth < 800) {
                window.advantages_slider = new Swiper('.block-advantages-container', {
                    wrapperClass: 'advantages-wrapper',
                    slideClass: 'advantage',
                    navigation: {
                        nextEl: ".advantage-button-next",
                        prevEl: ".advantage-button-prev",
                    },
                    autoplay: {
                        delay: 15000
                    },
                    loop: true
                });
            }

            window.addEventListener('resize', () => {
                if (window.innerWidth > 800) {
                    window.advantages_slider.destroy();
                }
            });
        }
    }
</script>

<style scoped lang="scss">
    @import "../styles/constants.scss";

    .mobile {
        &.block-advantages-container {
            padding-top: 20px;
            overflow: hidden;
            position: relative;
        }

        .advantages-wrapper {
            flex-wrap: nowrap;
            margin-top: 0px;
        }

        .advantage {
            width: 100%;
            flex-shrink: 0;
        }

        .advantage-img {
            width: 54px;
            height: 54px;
        }

        .advantage-text {
            font-size: 18px;
        }

        .header-text {
            font-size: 35px;
        }
    }

    .block-advantages-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1920px;

        background-color: #fff;
    }

    .advantages-wrapper {
        width: 100%;
        max-width: 1112px;
        margin-top: 10px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .advantage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
    }

    .advantage-text {
        margin-top: 15px;
        text-align: center;
        font-weight: 300;
        font-size: 20px;
    }

    .advantage-img {
        width: 60px;
        height: 60px;

        @each $advantage in 'guarantee', 'calendar', 'credit', 'piggy-bank', 'bonus', 'gift_black' {
            &.#{$advantage} {
                background: url("../images/icons/#{$advantage}.svg") no-repeat center;
                background-size: contain;
            }
        }

        &.tires {
            background: url("../images/icons/gift.png") no-repeat center;
            background-size: contain;
            width: 50px;
            margin-top: -20px;
        }
    }

    @media (max-width: 800px) {
        .advantage-button-next,
        .advantage-button-prev {
            width: 15px;
            height: 24px;
            background: no-repeat center/cover;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        
        .advantage-button-prev {
            background-image: url(../images/utils/arrow-left.svg);
            left: 5%;
        }
        
        .advantage-button-next {
            background-image: url(../images/utils/arrow-right.svg);
            right: 5%;
        }
    }
</style>
