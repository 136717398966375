var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-choice-container",class:[_vm.device_platform]},[_vm._m(0),_c('div',{staticClass:"bg-container"},[_c('div',{staticClass:"content-container"},[_c('selector-bar',{attrs:{"values":_vm.selectors,"cur_value":_vm.cur_selector,"b_cars_car":_vm.b_cars_car},on:{"selectModel":_vm.selectModel}}),_c('transition',{attrs:{"name":"appear"}},[(
                !['all', 'special'].some((el) => el === _vm.cur_selector) ||
                _vm.device_platform !== 'mobile'
              )?_c('div',{staticClass:"blue-bg"},[_c('transition',{attrs:{"name":"appear"}})],1):_vm._e()]),_c('div',{staticClass:"search-container"},_vm._l((_vm.cars_to_show),function(item){return _c('div',{staticClass:"search-item"},[_c('div',{staticClass:"image",class:[
                  _vm.orientation(item),
                  {
                    image_special: item.complectation_name.includes('Яндекс.Авто'),
                  },
                ]},[_c('img',{attrs:{"src":item.images},on:{"click":function($event){return _vm.getCall({
                      type: 'sale',
                      form: 'complectations',
                      button_text: 'ПОЛУЧИТЬ ЭТО ПРЕДЛОЖЕНИЕ',
                    })}}})]),_c('table',{staticClass:"info"},[_c('tr',[_c('td',{staticClass:"title"},[_vm._v("Комплектация")]),_c('td',{staticClass:"value"},[_vm._v(_vm._s(item.complectation_name))])]),_c('tr',[_c('td',{staticClass:"title"},[_vm._v("Технические характеристики")]),_c('td',{staticClass:"value"},[_vm._v(_vm._s(item.modification))])]),_c('tr',[_c('td',{staticClass:"title"},[_vm._v("Год")]),_c('td',{staticClass:"value"},[_vm._v(_vm._s(item.year))])]),_c('tr',[_c('td',{staticClass:"title"},[_vm._v("Номер")]),_c('td',{staticClass:"value"},[_vm._v(_vm._s(item.vin))])]),_c('tr',[_c('td',{staticClass:"title"},[_vm._v("Цвет")]),_c('td',{staticClass:"value"},[_vm._v(_vm._s(item.color))])])]),_c('div',{staticClass:"price-block"},[_vm._v("\n                  от "),_c('span',{staticClass:"value price price-block__price"},[_vm._v(_vm._s(_vm._f("finance")(+item.price)))]),_vm._v(" рублей\n              ")]),_c('div',{staticClass:"btn-blue",on:{"click":function($event){$event.stopPropagation();return _vm.getCall({
                    type: 'sale',
                    form: 'complectations',
                    callback_title: 'ПОЛУЧИТЬ ЛУЧШУЮ ЦЕНУ',
                  })}}},[_vm._v("\n                ПОЛУЧИТЬ ЛУЧШУЮ ЦЕНУ\n              ")])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_more_btn),expression:"show_more_btn"}],staticClass:"btn-white",on:{"click":_vm.incCarsNum}},[_vm._v("\n            ПОКАЗАТЬ БОЛЬШЕ КОМПЛЕКТАЦИЙ\n          ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-text"},[_vm._v("\n    Geely в наличии\n    "),_c('img',{staticClass:"header-text__bg",attrs:{"src":require("../images/background/bg_stock.png"),"alt":""}})])
}]

export { render, staticRenderFns }