<template>
    <div class="block-header-container"
         :class="device_platform">
        <div class="content-container">
            <div class="content-container__container container">
                <div class="logo-wrapper">
                    <div 
                        class="logo"
                        @click="getCall({type: 'sale', form:'header'})"
                    ></div>

                    <div class="name-container" @click="getCall({type: 'sale', form:'header'})">
                        <p class="dealer">{{CONSTANTS.dealer.toUpperCase()}}</p>
                        <p class="dealer-text">Официальный дилер</p>
                    </div>
                </div>

                <div class="address-worktime-container"
                    @click="scrollTo('contacts')"
                    v-if="device_platform !== 'mobile'">
                    <div class="office-address-container" v-for="office in offices" :key="office.address">
                        <img class="office-address-icon" src="../images/icons/map-pin.svg" alt="">

                        <div class="office-address-content">
                            <div class="office-address" @click="scrollTo('contacts')">
                                ШОССЕ РЕВОЛЮЦИИ, 86, САНКТ-ПЕТЕРБУРГ
                            </div>
                            <div class="office-worktime" v-html="office.worktime"></div>
                        </div>
                    </div>
                </div>

                <div class="phone-container">
                    <div 
                        class="logo-mobile"
                        @click="getCall({type: 'sale', form:'header'})"
                    ></div>
                    <div class="office-phone" v-for="office in offices" :key="office.address">
                        <img class="office-phone-icon" src="../images/icons/phone-call.svg" alt="">
                        <a href="tel:+78127779247" class="phone office-phone-link" id="phone">+7 (812) 777‑92‑47</a>
                    </div>
                </div>

                <div class="buttons-container"
                    v-if="device_platform !== 'mobile'">
                    <div class="btn-uncommon" :class="'btn-white'"
                        @click="getCall({type: 'sale', form:'header', callback_title: 'ЗАКАЗАТЬ ЗВОНОК', button_text: 'ЗАКАЗАТЬ ЗВОНОК'})">
                        ЗАКАЗАТЬ ЗВОНОК
                    </div>
                </div>
            </div>
        </div>
        <div class="header-menu"
             v-if="device_platform !== 'mobile'">
            <div class="menu-item" @click="scrollTo('cars')">МОДЕЛЬНЫЙ РЯД</div>
            <div class="menu-item" @click="scrollTo('configurator')">КОНФИГУРАТОР</div>
            <!-- <div class="menu-item" @click="scrollTo('interior')">ИНТЕРЬЕР GEELY</div> -->
            <!-- <div class="menu-item" @click="scrollTo('choice')">GEELY В НАЛИЧИИ</div> -->
            <div class="menu-item" @click="scrollTo('contacts')">КОНТАКТЫ</div>
        </div>
    </div>
</template>

<script>
    import Mixin from '../common/mixin';
    import $ from 'jquery';

    export default {
        name: 'block-header',
        components: {},
        directives: {},
        mixins: [Mixin],
        data() {
            let offices = this.CONSTANTS.offices;
            return {
                offices: offices
            }
        },
        mounted() {
            $('html').on('keyup', (event) => {
                if (event.keyCode === 27) {
                    this.show.creq = false;
                }
            });
        },
        methods: {
            scrollTo(where, delta) {
                let newhash = '#' + where;
                history.replaceState(null, null, newhash);

                var delta = 0;
                switch (where) {
                    case 'selector':
                        if (this.device_platform === 'desktop') {
                            if ($(window).width() < 1607) delta = 270;
                            else delta = 200;
                        } else delta = 130;

                        break;
                    case 'cars-wrapper':
                        if (this.device_platform === 'desktop') {
                            if ($(window).width() < 1607) delta = 270;
                            else delta = 200;
                        } else delta = 130;
                        break;
                    case 'choice':
                        if (this.device_platform === 'desktop') {
                            if ($(window).width() < 1607) delta = 130;
                            else delta = 50;
                        } else {
                            delta = -10;
                        }
                        break;
                    case 'contacts':

                        break;
                    default:
                        break;
                }
                this.$emit('scrollTo', where, delta);
            },
            getCall(data) {
                this.$emit('getCall', data);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../styles/constants.scss";
    .tablet {
        .logo {
            width: 155px;
            height: 27px;
            margin-right: 12px;
        }

        .name-container {
            padding-left: 12px;
        }

        .dealer {
            font-size: 14px;

            &-text {
                font-size: 8px;
            }
        }

        .address-worktime-container {
            margin-left: 15px;
        }

        .office-address-icon {
            max-width: 20px;
            margin-right: 7px;
        }

        .office-address,
        .office-worktime {
            font-size: 12px;
        }

        .phone-container {
            margin-left: 15px;
            font-size: 14px;

            .office-phone-icon {
                max-width: 20px;
            }
        }

        .buttons-container {
            margin-left: 15px;

            .btn-white {
                font-size: 12px;
                width: 160px;
                height: 44px;
            }
        }

        .menu-item {
            font-size: 16px;
            padding: 0 30px;
        }
    }

    .mobile {
        .content-container {
            flex-direction: column;
            height: 80px;
        }

        .logo {
            display: none;

            &-mobile {
                display: block;
                background: url("../images/logos/white/logo-horizontal.png") no-repeat center/contain;
                width: 112px;
                height: 27px;
                box-sizing: content-box;
                cursor: pointer;
            }
        }

        .name-container {
            order: 0;
            flex-direction: column;
            align-items: center;
            margin: 15px 0px 20px;
            border: none;
        }

        .dealer {
            font-size: 17px;
            text-align: center;
        }

        .dealer-text {
            font-size: 11px;
        }

        .buttons-container {
            order: 2;
            margin: 0px 0px 15px;
        }

        .phone-container {
            order: 3;
            margin: 0px 0px 10px;
            font-size: 14px;
        }
        .office-phone-icon{
          display: none;
        }

        .office-address {
            margin-bottom: 0;
        }

        .address-worktime-container {
            order: 4;
            margin: 0px 0px 15px;
        }
        .header-menu {
            flex-wrap: wrap;
        }
        .menu-item {
            width: 50%;
            height: 50%;
            border-bottom: 1px solid #ccc;
            text-align: center;
            padding: 0 10px;
        }
    }

    .office-address-container {
        display: flex;
        align-items: center;
    }

    .office-address-icon {
        margin-right: 15px;
    }

    .office-phone {
        display: flex;
        align-items: center;
    }

    .office-phone-icon {
        max-width: 22px;
        max-height: 22px;
        margin-right: 12px;
    }

    .office-phone-link {
        font-weight: 500;
    }

    .block-header-container {
        position: fixed;
        top: 0px;
        width: 100%;
        background: #fff;
        z-index: 500;
        height: auto;
    }

    .content-container {
        width: 100%;
        margin: auto;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $dark-blue;
        flex-wrap: wrap;
        top: 0;
        left: 0;
        z-index: 5;

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .header-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        cursor: pointer;
        z-index: 5;
    }

    .menu-item {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 300;
        font-size: 20px;
        padding: 0 40px;
        border-right: 1px solid #ccc;
        &:first-of-type {
            border-left: 1px solid #ccc;
        }
    }

    .logo-wrapper {
        display: flex;
        align-items: center;
    }

    .logo {
        background: url("../images/logos/white/logo-horizontal.png") no-repeat center/contain;
        width: 192px;
        height: 27px;
        margin-right: 20px;
        box-sizing: content-box;
        cursor: pointer;

        &-mobile {
            display: none;
        }
    }

    .name-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 19px;
        border-left: 0.73px solid #FFFFFF;
        cursor: pointer;
    }

    .dealer {
        font-size: 18px;
        font-weight: 400;
        color: white;        
    }

    .dealer-text {
        font-size: 11px;
        font-weight: 300;
        color: white;
    }

    .address-worktime-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 40px;
        cursor: pointer;
    }

    .phone-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        color: white;
        font-size: 18px;
        margin-left: 60px;
    }

    .phone {
        color: white;
    }

    .office-address {
        font-size: 13px;
        font-weight: 300;
        color: white;
    }

    .office-worktime {
        font-size: 13px;
        font-weight: 300;
        color: white;
        text-transform: uppercase;
    }

    .buttons-container {
        margin-left: 60px;
        display: flex;
    }

    @media (max-width: 1280px) {
        .desktop {
            &.block-header-container {
                
                .address-worktime-container {
                    margin-left: 20px;
                }

                .phone-container {
                    margin-left: 40px;
                }

                .buttons-container {
                    margin-left: 40px;
                }
            }
        }
    }
</style>
