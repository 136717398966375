export default {
	CARS: [
		{
			id: "monjaro",
			name: "Monjaro",
			price: false,
			// price_new: "1 774 990",
			benefit: "750 000",
			credit_ben: "350 000",
			trade_in: "350 000",
			kasko_ben: "",
			inst_plan: false,
			credit: true,
			payment: true,
			configurable: true,
		},
		{
			id: "preface",
			name: "PREFACE",
			price: "2 699 990",
			// price_new: "1 349 990",
			// benefit: "450 000",
			trade_in: "260 000",
			credit_ben: "260 000",
			kasko_ben: "",
			inst_plan: false,
			credit: true,
			payment: true,
			configurable: true,
			in_stock: true,
		},
		{
			id: "okavango",
			name: "OKAVANGO",
			price: "3 029 990",
			// price_new: "1 349 990",
			// benefit: "450 000",
			trade_in: "260 000",
			credit_ben: "260 000",
			kasko_ben: "",
			inst_plan: false,
			credit: true,
			payment: true,
			configurable: true,
			in_stock: true,
		},
		{
			id: "atlas_new",
			name: "Atlas NEW",
			price: "2 879 990",
			// price_new: "1 349 990",
			// benefit: "350 000",
			trade_in: "260 000",
			credit_ben: "260 000",
			kasko_ben: "",
			inst_plan: false,
			credit: true,
			payment: true,
			configurable: true,
			in_stock: true,
		},
		{
			id: "coolray_new",
			name: "Coolray NEW",
			// price: "1 649 990",
			// price_new: "1 349 990",
			benefit: "300 000",
			trade_in: "260 000",
			credit_ben: "260 000",
			kasko_ben: "",
			inst_plan: false,
			credit: true,
			payment: true,
			configurable: true,
			in_stock: true,
		},
		{
			id: "emgrand",
			name: "Emgrand",
			// price: "1 649 990",
			// price_new: "1 349 990",
			benefit: "350 000",
			trade_in: "260 000",
			credit_ben: "260 000",
			kasko_ben: "",
			inst_plan: false,
			credit: true,
			payment: true,
			configurable: true,
			in_stock: false,
		},
		{
			id: "tugella-facelift",
			name: "Tugella",
			car_name: "TUGELLA",
			// price: "2 124 990",
			// price_new: "1 774 990",
			// bonus: "100 000",
			benefit: "680 000",
			credit_ben: "350 000",
			trade_in: "350 000",
			kasko_ben: "",
			inst_plan: false,
			credit: true,
			payment: true,
			configurable: true,
		},

		// {
		//   id: "coolray",
		//   name: "Coolray",
		//   // price: "1 649 990",
		//   // price_new: "1 349 990",
		//   benefit: "650 000",
		//   trade_in: "260 000",
		//   credit_ben: "260 000",
		//   kasko_ben: "",
		//   inst_plan: false,
		//   credit: true,
		//   payment: true,
		//   configurable: true,
		// },
		{
			id: "atlas-pro",
			name: "Atlas PRO",
			benefit: "600 000",
			credit_ben: "350 000",
			trade_in: "350 000",
			kasko_ben: "",
			inst_plan: false,
			credit: true,
			payment: true,
			configurable: true,
		},

		/* {
    id: "tugella",
    name: "TUGELLA",
    price: "2 869 990",
    price_new: "2 469 990",
    benefit: "750 000",
    trade_in: "350 000",
    credit_ben: "350 000",
    kasko_ben: "",
    inst_plan: false,
    credit: true,
    payment: true,
    configurable: true,
  }, */
		// {
		// id: "belgee-x50",
		// name: "Belgee X50",
		// // price: "1 649 990",
		// // price_new: "1 349 990",
		// benefit: "350 000",
		// trade_in: "260 000",
		// credit_ben: "260 000",
		// kasko_ben: "",
		// inst_plan: false,
		// credit: true,
		// payment: true,
		// configurable: true,
		// in_stock: false,
		// },
	],
};
